<template>
  <section v-if="company" class="company">
    <div class="header">
      <div class="breadcrumbs">
        <PageHeader>
          <router-link :to="{ name: 'Reviews', params: { tabId: 1 } }">
            Reviews
          </router-link>
        </PageHeader>
        <img src="/img/icons/breadcrumb-arrow-black.svg" class="breadcrumb-arrow" alt="">
        <span class="company-name">{{ company.company }}</span>
      </div>
    </div>
    <section class="content">
      <div class="company-info">
        <div class="logo">
          <img
            :src="`/img/companies/${company.logo}`"
            height="75"
            width="75"
            :alt="company.company"
          >
        </div>
        <div class="title-and-rate">
          <span class="title">{{ company.company }}</span>
          <v-rating
            :value="company.rateGeneral"
            hide-details
            dense
            size="20"
            color="var(--rating-star-color)"
            background-color="var(--secondary-border-color)"
            class="rating"
          />
        </div>
        <div class="reviews">
          <div v-for="(phase, i) in getPhasesRates" :key="i" class="phase-rate">
            <span>{{ phase.rate || 0 }}</span>
            <v-icon size="18" color="var(--text-color)">
              mdi-star
            </v-icon>
            <span>{{ phase.title }}</span>
          </div>
        </div>
      </div>

      <div class="search">
        <v-text-field
          v-model="searchJobTitleValue"
          label="Job Title"
          type="text"
          height="40"
          dense
          solo
          flat
          rounded
          hide-details
          :disabled="isSearchDisabled"
          background-color="var(--background-dashboard-color)"
          class="search-input"
          @input="searchReviews"
        />
        <AppButton
          height="40"
          class="search-button"
          :disabled="isSearchDisabled"
          dark
          color="var(--button-primary-color)"
          @click="searchReviews"
        >
          Search
        </AppButton>
      </div>

      <CompanyPageSort :is-sort-disabled="!searchedReviews.length" @sortReviews="sortReviews" />

      <div class="review-cards-list">
        <IndividualReviewCard
          v-for="(review, i) in searchedReviews"
          :key="i"
          :review="review"
          @openReviewRateModal="openReviewViewModal(review)"
        />
      </div>

      <v-progress-circular
        v-if="isLoading"
        class="loader-main"
        size="50"
        width="5"
        indeterminate
        color="var(--text-color)"
      />

      <div v-if="isEmpty" class="empty">
        Your request did not match any reviews...
      </div>

      <ScrollPaginationObserver v-if="isRenderScrollPagination" @loadMore="loadMore" />
    </section>

    <ReviewsViewModal
      :is-reviews-view-modal-open.sync="isReviewsViewModalOpen"
      :review="selectedReview"
    />
  </section>
</template>

<script>
import api from '@/api'
import CompanyPageSort from '@/components/CompanyPageSort'
import IndividualReviewCard from '@/components/IndividualReviewCard'
import ScrollPaginationObserver from '@/components/ScrollPaginationObserver'
import ReviewsViewModal from '@/components/ReviewsViewModal'

export default {
  name: 'CompanyPage',
  components: {
    CompanyPageSort,
    IndividualReviewCard,
    ScrollPaginationObserver,
    ReviewsViewModal
  },
  data() {
    return {
      isLoading: true,
      company: null,
      isSearching: false,
      isEmpty: false,
      companyReviews: [],
      searchedReviews: [],
      isCanShowMore: false,
      reviewsParams: {
        pageNumber: 0,
        pageSize: 50,
        filterByJobTitle: '',
        sortBy: ''
      },
      isSearchDisabled: false,
      searchJobTitleValue: '',
      debounce: null,
      isReviewsViewModalOpen: false,
      selectedReview: {}
    }
  },
  computed: {
    companyId() {
      return this.$route.params.id || ''
    },
    getPhasesRates() {
      const {
        rateApplication,
        rateScreening,
        rateInterviewI,
        rateInterviewII,
        rateOffer
      } = this.company

      const ratePhases = [
        {
          title: 'Application',
          rate: rateApplication
        },
        {
          title: 'Screening',
          rate: rateScreening
        },
        {
          title: 'Interview I',
          rate: rateInterviewI
        },
        {
          title: 'Interview II',
          rate: rateInterviewII
        },
        {
          title: 'Offer',
          rate: rateOffer
        }
      ]

      return ratePhases
    },
    isRenderScrollPagination() {
      return !this.isEmpty && !!this.searchedReviews.length && this.isCanShowMore
    }
  },
  watch: {
    companyId() {
      this.fetchCompany()
    }
  },
  created() {
    this.fetchCompany()
    this.fetchReviewsForCompany()
  },
  methods: {
    async fetchCompany() {
      const { data } = await api.users.getCompany(this.companyId)
      if (data) this.company = data
    },
    async fetchReviewsForCompany() {
      this.isLoading = true
      const { data } = await api.users.getReviewsForCompany(this.companyId, this.reviewsParams)
      this.isLoading = false

      if (!data) return

      if (!this.isSearching) {
        this.companyReviews = data || []
        this.searchedReviews = [...this.searchedReviews, ...data]
        this.isCanShowMore = data.length === this.reviewsParams.pageSize
        return
      }

      this.searchedReviews = data || []
      this.isEmpty = !this.searchedReviews.length
    },
    resetReviews() {
      this.isEmpty = false
      this.searchedReviews = []
      this.reviewsParams.pageNumber = 0
      this.fetchReviewsForCompany()
    },
    sortReviews(sortTitle) {
      this.reviewsParams.sortBy = sortTitle
      this.resetReviews()
    },
    searchReviews(jobTitle) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.isSearching = true
        this.reviewsParams.filterByJobTitle = jobTitle
        this.resetReviews()
      }, 1200)
    },
    loadMore() {
      this.reviewsParams.pageNumber += 1
      this.fetchReviewsForCompany()
    },
    openReviewViewModal(review) {
      this.selectedReview = review
      this.isReviewsViewModalOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.company {
  @include default-page-paddings;

  padding-bottom: 0;

  .header {
    padding-bottom: 50px;

    @include breakpoint-reverse(small) {
      padding-bottom: 0;
    }

    .breadcrumbs {
      display: flex;
      align-items: baseline;
      margin-bottom: 30px;
    }

    .breadcrumb-arrow {
      margin-left: 30px;
    }

    .company-name {
      margin-left: 17px;
      font-size: var(--font-size-sub);
    }
  }

  .search {
    display: grid;
    align-items: center;
    grid-template-columns: 220px 138px;
    margin-bottom: 30px;
    gap: 21px;

    @include breakpoint-reverse(small) {
      grid-template-columns: 1fr auto;
      gap: 15px;
    }
  }

  .search-input::v-deep {
    .v-label {
      font-size: var(--font-size-base-sm);
      color: var(--tertiary-text-color);
    }
  }

  .search-button {
    justify-self: center;
    max-width: 138px;
    box-shadow: none;

    &::v-deep {
      .text {
        font-size: var(--font-size-base-sm);
        line-height: var(--line-height-xs);
        text-shadow: none;
        color: var(--primary-button-text-color);
      }

      &.v-btn.v-btn--disabled {
        background-color: var(--button-primary-color) !important;
        opacity: 0.5;
      }
    }
  }

  .content {
    height: calc(100% - 122px); // minus page header height
    padding: 48px 56px;
    background-color: var(--background-secondary-color);

    @include breakpoint-reverse(small) {
      height: 100%;
      margin: 0 -12px;
      padding: 24px;
    }
  }

  .company-info {
    display: flex;
    gap: 30px;
    margin-bottom: 40px;

    @include breakpoint-reverse(small) {
      flex-direction: column;
    }
  }

  .logo > img {
    object-fit: contain;

    @include breakpoint-reverse(small) {
      width: 100%;
    }
  }

  .title-and-rate {
    .title {
      font-weight: 500;
      font-size: var(--font-size-xlg);
    }

    .rating {
      margin-left: -4px;
    }
  }

  .reviews {
    width: 100%;
    max-width: 250px;

    @include breakpoint-reverse(small) {
      max-width: 100%;
    }

    .phase-rate {
      display: grid;
      align-items: center;
      grid-template-columns: 22px 16px auto;
      padding: 2px 24px;
      font-size: var(--font-size-base-sm);
      gap: 10px;

      &:nth-child(odd) {
        background-color: var(--background-highlight-color);
      }

      &:nth-child(even) {
        background-color: var(--background-tertiary-color);
      }
    }
  }

  .review-cards-list {
    @include default-review-cards-list;
  }

  .empty {
    margin: auto;
    width: fit-content;
  }
}
</style>
