import { render, staticRenderFns } from "./CompanyPageSort.vue?vue&type=template&id=179f960f&scoped=true&"
import script from "./CompanyPageSort.vue?vue&type=script&lang=js&"
export * from "./CompanyPageSort.vue?vue&type=script&lang=js&"
import style0 from "./CompanyPageSort.vue?vue&type=style&index=0&id=179f960f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "179f960f",
  null
  
)

export default component.exports