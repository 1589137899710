<template>
  <div class="sort-list">
    <div
      v-for="item in sortList"
      :key="item.id"
    >
      <button
        v-if="!isHiddenForUserReviews(item.value)"
        class="sort-item"
        :class="{ active: item.id === activeSortId }"
        :disabled="isSortDisabled"
        @click="sortReviews(item.id)"
      >
        {{ item.title }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompanyPageSort',
  props: {
    isSortDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isUserReviews: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      activeSortId: 0,
      sortList: [
        {
          id: 1,
          title: 'Highest Rated',
          value: 'rate-desc'
        },
        {
          id: 2,
          title: 'Lowest Rated',
          value: 'rate-asc'
        },
        {
          id: 3,
          title: 'Recently Reviewed',
          value: 'recently'
        }
      ]
    }
  },
  methods: {
    isHiddenForUserReviews(sortValue) {
      return this.isUserReviews && sortValue === 'most'
    },
    sortReviews(id) {
      if (this.activeSortId === id) {
        this.activeSortId = 0
        this.$emit('sortReviews', '')
        return
      }

      const sortItem = this.sortList.find(item => item.id === id)
      this.activeSortId = sortItem.id
      this.$emit('sortReviews', sortItem.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.sort-list {
  display: grid;
  grid-template-columns: repeat(4, 169px);
  margin-bottom: 56px;
  gap: 16px;

  @include breakpoint-reverse(small) {
    grid-template-columns: 1fr;
  }
}

.sort-item {
  border-radius: var(--border-radius-default-sm);
  padding: 9px 0;
  width: 100%;
  box-shadow: var(--text-shadow-default);
  font-size: var(--font-size-base-sm);
  background-color: var(--background-dashboard-color);
  transition: box-shadow 0.2s ease-in-out;

  &.active {
    box-shadow: none;
  }
}
</style>
